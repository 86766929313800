/*
 * Copyright 2018-2020 National Geographic Society
 *
 * Use of this software does not constitute endorsement by National Geographic
 * Society (NGS). The NGS name and NGS logo may not be used for any purpose without
 * written permission from NGS.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *   https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */
import { createTheme, responsiveFontSizes } from '@material-ui/core';

const COLORS = {
  SECONDARY_LIGHT: '#7ecace',
  // SECONDARY_MAIN: '#2CD787',
  // SECONDARY_DARK: '#2CD787',

  SECONDARY_MAIN: '#0099A1',
  SECONDARY_DARK: '#006b70',
  TEXT_ERROR: '#ffcdd2',
  GREY_0: '#FFFFFF',
  GREY_1: '#F8F9FA',
  GREY_2: '#E9ECEF',
  GREY_3: '#DEE2E6',
  GREY_4: '#CED4DA',
  GREY_5: '#ADB5BD',
  GREY_6: '#6C757D',
  // bruna update
  GREY_7: '#455A64',
  // GREY_7: '#495057',
  GREY_8: '#343A40',
  GREY_9: '#212529',
};

const BACKGROUND_COLOR = {
  default: COLORS.GREY_9,
  paper: COLORS.GREY_8,
};

const SPACING = 8;

export default (themeConfig) =>
  responsiveFontSizes(
    createTheme({
      spacing: SPACING,
      palette: {
        type: 'dark',
        primary: {
          light: COLORS.GREY_1,
          main: COLORS.GREY_2,
          dark: COLORS.GREY_3,
        },
        secondary: {
          light: themeConfig?.colors?.primary?.light || COLORS.SECONDARY_LIGHT,
          main: themeConfig?.colors?.primary?.main || COLORS.SECONDARY_MAIN,
          dark: themeConfig?.colors?.primary?.dark || COLORS.SECONDARY_DARK,
        },
        text: {
          primary: COLORS.GREY_0,
        },
        background: {
          ...BACKGROUND_COLOR,
        },
        grey: {
          50: COLORS.GREY_1,
          100: COLORS.GREY_2,
          200: COLORS.GREY_3,
          300: COLORS.GREY_4,
          400: COLORS.GREY_5,
          500: COLORS.GREY_6,
          600: COLORS.GREY_7,
          700: COLORS.GREY_8,
          800: COLORS.GREY_9,
          900: COLORS.GREY_9,
        },
      },
      typography: {
        h2: {
          textTransform: 'uppercase',
        },
        subtitle1: {
          fontWeight: 600,
        },
        subtitle2: {},
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              background: BACKGROUND_COLOR.default,
              position: 'relative',
              minHeight: '100vh',
              scrollbarWidth: 'thin',
              scrollbarColor: `${BACKGROUND_COLOR.paper} ${BACKGROUND_COLOR.default}`,
            },
            a: {
              color: 'inherit',
              cursor: 'pointer',
              textDecoration: 'none',
            },
            svg: {
              verticalAlign: 'middle',
            },
            'ul, li': {
              paddingLeft: '0',
            },
            '::-webkit-scrollbar': {
              width: 12,
            },
            '::-webkit-scrollbar-track': {
              background: COLORS.GREY_8,
            },
            '::-webkit-scrollbar-corner': {
              background: COLORS.GREY_8,
            },
            ':focus': {
              outline: 'none',
            },
            '::-webkit-scrollbar-thumb ': {
              backgroundColor: COLORS.GREY_7,
              borderRadius: SPACING,
              border: `3px solid ${COLORS.GREY_8}`,
            },
          },
        },
        MuiButton: {
          containedSecondary: {
            color: COLORS.GREY_9,
          },
        },
        MuiCardContent: {
          root: {
            '&:last-child': {
              paddingBottom: SPACING * 2,
            },
          },
        },
        MuiChip: {
          root: {
            backgroundColor: COLORS.GREY_1,
            color: COLORS.GREY_9,
          },
          deleteIcon: {
            color: COLORS.GREY_9,
            '&:hover': {
              color: COLORS.GREY_7,
            },
          },
        },
        MuiFab: {
          root: {
            backgroundColor: COLORS.GREY_8,
            color: COLORS.GREY_1,
            '&:hover': {
              backgroundColor: COLORS.GREY_7,
            },
          },
        },
        MuiFormHelperText: {
          root: {
            '&$error': {
              color: COLORS.TEXT_ERROR,
            },
          },
        },
        MuiInputLabel: {
          root: {
            '&$error': {
              color: COLORS.TEXT_ERROR,
              '& *': {
                color: COLORS.TEXT_ERROR, // the required asterisks
              },
            },
          },
        },
        MuiListItem: {
          gutters: {
            paddingLeft: SPACING * 2,
            paddingRight: SPACING * 2,
          },
        },
        MuiMenu: {
          paper: {
            backgroundColor: COLORS.GREY_7,
          },
        },
        MuiOutlinedInput: {
          root: {
            backgroundColor: COLORS.GREY_8,
            '&$error $notchedOutline': {
              borderColor: '#f44336',
            },
          },
        },
        MuiTab: {
          textColorPrimary: {
            '&$selected': {
              color: COLORS.GREY_1,
            },
          },
        },
        MuiTableRow: {
          root: {
            '&:nth-of-type(even)': {
              backgroundColor: `${COLORS.GREY_3}11`,
            },
            '& td': {
              borderBottom: 0,
            },
          },
        },
        MuiTooltip: {
          tooltip: {
            backgroundColor: COLORS.GREY_7,
            boxShadow:
              '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
          },
        },
        MuiTypography: {
          colorError: {
            color: COLORS.TEXT_ERROR,
          },
        },
      },
    })
  );
